/* eslint-disable max-len */
import React from 'react'
import {
  Node__Career_Pathway,
  Paragraph__Search_Cta_Card,
  Node__Awards,
} from '../../types/generated'
import CustomLink from '../CustomLink'
import RenderIf from '../RenderIf'

interface SearchCtaCard {
  card: Paragraph__Search_Cta_Card
}

interface CareerPathway {
  card: Node__Career_Pathway
}

interface NodeAwards {
  card: Node__Awards
}

interface Props {
  showBackground?: boolean
  containerClassName?: string
  imageClassName?: string
}

const SearchCard = ({
  card,
  showBackground = false,
  containerClassName = '',
  imageClassName = 'h-10 w-10',
}: SearchCtaCard & CareerPathway & NodeAwards & Props) => {
  // eslint-disable-next-line max-len
  const image =
    card?.relationships?.field_search_cta_image?.relationships?.field_media_image?.uri?.url ??
    card?.relationships?.field_icon_image?.relationships?.field_media_image?.uri?.url ??
    '/sites/default/files/2021-08/chrome-outlined.png'

  const linkUri =
    card?.field_link?.uri_alias ??
    card?.field_link?.uri?.replace('internal:', '') ??
    card?.path?.alias
  const linkText = card?.field_link?.title ?? card?.field_teaser_cta_text
  const inner = (
    <>
      <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
        <div
          className={`${
            showBackground ? 'bg-sdusd-blue rounded-xl shadow-lg' : ''
          } absolute top-0 p-3 inline-block transform -translate-y-1/2`}
        >
          <img
            className={imageClassName}
            src={`https://discover-admin.sandiegounified.org${image}`}
            alt={
              card?.relationships?.field_search_cta_image?.thumbnail?.alt ??
              card?.relationships?.field_icon_image?.thumbnail?.alt ??
              ''
            }
            loading="lazy"
          />
        </div>
        <div className="text-xl font-medium text-gray-900">{card?.field_title ?? card?.title}</div>
        <RenderIf condition={!!(card?.field_subtitle ?? card?.body?.summary)}>
          <p className="mt-4 text-base text-gray-500">
            {card?.field_subtitle ?? card?.body?.summary}
          </p>
        </RenderIf>
      </div>
      <RenderIf condition={!!linkText}>
        <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8 border-t-2 border-gray-100">
          <span className="text-sdusd-blue border-transparent focus-border-bottom hover:border-sdusd-blue border-b-2 active:ring-2 focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50">
            {linkText}
          </span>
          <span aria-hidden="true" className="text-sdusd-blue">
            {' '}
            &rarr;
          </span>
        </div>
      </RenderIf>
    </>
  )

  if (linkUri) {
    return (
      <CustomLink
        className={`flex flex-col bg-gray-50 rounded-2xl shadow-xl ${containerClassName}`}
        to={linkUri}
      >
        {inner}
      </CustomLink>
    )
  }

  return (
    <div className={`flex flex-col bg-gray-50 rounded-2xl shadow-xl ${containerClassName}`}>
      {inner}
    </div>
  )
}

export default SearchCard
