import React from 'react'
import { graphql } from 'gatsby'
import SearchCard from '../../SearchCard'
import { ParagraphSearchCtaFieldsFragment } from '../../../types/generated'

export const ParagraphSearchCtaFields = graphql`
  fragment ParagraphSearchCtaFields on paragraph__search_cta {
    __typename
    id
    relationships {
      field_cards {
        id
        field_title
        field_subtitle
        field_link {
          uri
          title
          uri_alias
        }
        relationships {
          field_search_cta_image {
            thumbnail {
              alt
            }
            relationships {
              field_media_image {
                uri {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

const ParagraphSearchCta = (data: ParagraphSearchCtaFieldsFragment) => (
  <section className="max-w-7xl mt-8 mx-auto" aria-labelledby="contact-heading">
    <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-x-8">
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {data?.relationships?.field_cards?.map?.((card: any) => (
        <SearchCard showBackground card={card} key={card.id} />
      ))}
    </div>
  </section>
)

export default ParagraphSearchCta
